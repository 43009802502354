import { TinyColor } from '@ctrl/tinycolor';

export function generateMaterialPalette(hexColor: string): Record<string, string> {
    const baseLight = new TinyColor('#ffffff');
    const baseDark = new TinyColor('#000000');

    const palette: Record<string, string> = {};
    const steps = [
        0,
        50,
        100,
        200,
        300,
        400,
        500,
        600,
        700,
        800,
        900
    ];
    const amounts = [
        0,
        12,
        30,
        50,
        70,
        85,
        100,
        87,
        70,
        54,
        25
    ];

    steps.forEach((step, i) => {
        const base = step <= 500 ? baseLight : baseDark;
        palette[`${step}`] = new TinyColor(base).mix(hexColor, amounts[i])
            .toHexString();
    });

    return palette;
}
