import { adjustHue, hexToHSL, hslToHex } from './Utils.Common';

// Function to generate a triadic color palette
export function generateTriadicPalette(baseHex: string, adjustment: number): Record<string, string> {
    const baseHSL = hexToHSL(baseHex);
    const triadicHSL = adjustHue(baseHSL, adjustment);
    const palette: Record<string, string> = {};

    // Define the steps
    const steps = [
        0,
        50,
        100,
        200,
        300,
        400,
        500,
        600,
        700,
        800,
        900
    ];

    steps.forEach((step) => {
        let [
            h,
            s,
            l]: [number, number, number
        ] = triadicHSL;

        if (step === 0) {
            l = 1; // White
        } else {
            // Gradually decrease lightness for other steps
            l = 1 - (step / 1000);
        }

        palette[`${step}`] = hslToHex(h, s, Math.max(0, Math.min(1, l)));
    });

    return palette;
}
