
export function hexToHSL(hex: string): [number, number, number] {
    // Convert hex to RGB
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b); const min = Math.min(r, g, b);
    let h: number = 0;
    let s: number = 0;
    const l = (max + min) / 2;

    if (max === min) {
        h = 0;
        s = 0; // Achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
            default: break;
        }
        h /= 6;
    }

    return [h, s, l];
}

export function hexToRGB(hex: string): [number, number, number] {
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;
    return [r, g, b];
}

export function hslToHex(h: number, s: number, l: number): string {
    const hue2rgb = (p: number, q: number, t: number): number => {
        if (t < 0) {
            t += 1;
        }
        if (t > 1) {
            t -= 1;
        }
        if (t < 1 / 6) {
            return p + ((q - p) * 6 * t);
        }
        if (t < 1 / 2) {
            return q;
        }
        if (t < 2 / 3) {
            return p + ((q - p) * (2 / 3 - t) * 6);
        }
        return p;
    };

    let r: number = 0;
    let g: number = 0;
    let b: number = 0;
    if (s === 0) {
        r = l;
        g = l;
        b = l; // Achromatic
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - (l * s);
        const p = (2 * l) - q;
        r = hue2rgb(p, q, h + (1 / 3));
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - (1 / 3));
    }

    return `#${Math.round(r * 255).toString(16)
        .padStart(2, '0')}${Math.round(g * 255).toString(16)
        .padStart(2, '0')}${Math.round(b * 255).toString(16)
        .padStart(2, '0')}`;
}

export function rgbToHSL(r: number, g: number, b: number): [number, number, number] {
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h: number = 0;
    let s: number = 0;
    const l = (max + min) / 2;

    if (max === min) {
        h = 0;
        s = 0; // Achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = ((g - b) / d) + (g < b ? 6 : 0); break;
            case g: h = ((b - r) / d) + 2; break;
            case b: h = ((r - g) / d) + 4; break;
            default: break;
        }

        h /= 6;
    }

    return [h, s, l];
}

export function adjustHue(hsl: [number, number, number], adjustment: number): [number, number, number] {
    // Adjusts the hue by a given amount, wrapping around the color wheel if necessary
    return [
        (hsl[0] + adjustment + 1) % 1,
        hsl[1],
        hsl[2]
    ];
}
