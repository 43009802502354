import { TinyColor } from '@ctrl/tinycolor';
import { generateMaterialPalette } from './Utils.Material.2014.2';

// Function to calculate the complementary color in HSL format
function complementaryHSL(hsl: [number, number, number]): [number, number, number] {
    return [
        (hsl[0] + 0.5) % 1,
        hsl[1],
        hsl[2]
    ];
}

// Function to generate a complementary color palette
export function generateComplementaryPalette(baseHex: string): Record<string, string> {
    // Const baseHSL = hexToHSL(baseHex);
    // Const complementaryHSLColor = complementaryHSL(baseHSL);
    // Const palette: Record<string, string> = {};

    // // Define the steps and their corresponding lightness adjustments
    // Const steps = [0, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

    // Steps.forEach(step => {
    //     Let [h, s, l]: [number, number, number] = complementaryHSLColor;

    //     If (step === 0) {
    //         L = 1; // White
    //     } else {
    //         // Gradually decrease lightness for other steps
    //         L = 1 - (step / 1000);
    //     }

    //     Palette[`${step}`] = hslToHex(h, s, Math.max(0, Math.min(1, l)));
    // });

    // Return palette;

    return generateMaterialPalette(new TinyColor(baseHex).complement()
        .toHexString());
}
